import React, { useState, useEffect } from 'react';
import './App.css';
import dragon from './dragon.png';
import field from './field.jpg';

function App() {
  const [inputText, setInputText] = useState(""); // To store the text input
  const [apiResponse, setApiResponse] = useState(""); // To store API response

  const [position, setPosition] = useState({ x: 0, y: 180 });
  const [jumping, setJumping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let interval;
    if (jumping) {
      interval = setInterval(() => {
        const randomX = Math.floor(Math.random() * 300);
        setPosition(prev => ({ ...prev, x: randomX }));
      }, 200);

      setTimeout(() => {
        clearInterval(interval);
        setJumping(false);
      }, 2000);
    }
  }, [jumping]);

  const startJumping = () => {
    setJumping(true);
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleApiCall = async () => {
    setIsLoading(true);
    const requestBody = {
      messages: [
        { role: "system", content: "You are a friendly dragon who is friends with a girl named Baker. Limit your responses to 120 words. Topics that Baker likes talking about: her birthday is soon, she likes dragons and axolotls, and she likes jokes." },
        { role: "user", content: inputText }
      ]
    };
    
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer nlGHQDWLx3tXxb1HLLEoHsdf_D8wSA04dw36MJs5',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    };

    const workerUrl = "https://dragonproxy.esellars.workers.dev/proxy?modify&proxyUrl=" + encodeURIComponent("https://api.cloudflare.com/client/v4/accounts/689fb56219395d2ebd9f9ff0f24279ff/ai/run/@cf/meta/llama-2-7b-chat-int8");
    const response = await fetch(workerUrl, requestOptions);
    const data = await response.json();
    console.log(data);

    setIsLoading(false);

    if (data && data.success && data.result && data.result.response) {
      setApiResponse(data.result.response);
    } else {
      console.error("Unexpected API response", data);
    }
  };

  return (
  <div className="App">
    <img src={field} className="App-field" alt="grassy field" />
    <header className="App-header">
      <input
        type="text"
        placeholder="Type to interact with the dragon"
        value={inputText}
        onChange={handleInputChange}
      />
      <button onClick={handleApiCall}>Talk</button>
      {isLoading && <div className="loading-graphic">Loading...</div>} {/* Placeholder for your loading graphic */}
    </header>
    <div className="response-box">
      {apiResponse && <p>{apiResponse}</p>}
    </div>
    <img
      src={dragon}
      className={`App-dragon ${jumping ? "jumping" : ""}`}
      alt="cute dragon"
      style={{ left: `${position.x}px`, bottom: `${position.y}px` }}
      onClick={startJumping}
    />
  </div>
);
}

export default App;